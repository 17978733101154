<template>
  <div class="wishlist">
    <div class="row m0">
      <div class="col-xs-12 center-flex-text bg-cl-alternative cl-white relative">
        <h2 class="title-wishlist my30">
          {{ productsInWishlist.length ? $t('Wishlist') : $t('Your wishlist is empty.') }}
        </h2>
        <div class="flex absolute close-wishlist" @click="closeWishlist">
          <close-svg class="pointer" width="26px" height="100%" color="#ffffff" />
        </div>
      </div>
      <div v-if="!productsInWishlist.length" class="ml30 mt60 h4">
        {{ $t("Don't hesitate and") }}
        <div class="link-box" @click="closeWishlist">
          <router-link class="cl-primary-orange" :to="localizedRoute('/kategorie-produktow')">
            {{ $t('browse our catalog') }}
          </router-link>
        </div>
        {{ $t('to find something beautiful for You!') }}
      </div>
    </div>
    <ul class="m0 p0 products">
      <product v-for="wishlistProduct in productsInWishlist" :key="wishlistProduct.id" :product="wishlistProduct" />
    </ul>
    <div class="save-margin">
      <div class="row m0 py20 center-xs">
        <div class="col-xs-12 p0 align-center">
          <h3 class="m0 cl-light-gray">
            {{ $t('My Recently viewed products') }}
          </h3>
        </div>
      </div>
      <div class="row m0">
        <div class="col-xs-12" v-if="sliceRecentlyView && sliceRecentlyView.length > 0">
          <product-listing columns="4" :products="sliceRecentlyView" />
        </div>
        <div class="col-xs-12 h4 ml30" v-else>
          <p>{{ $t('No products yet') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wishlist from '@vue-storefront/core/compatibility/components/blocks/Wishlist/Wishlist'
import Product from 'theme/components/core/blocks/Wishlist/Product'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
import RecentlyViewed from '@vue-storefront/core/modules/recently-viewed/components/RecentlyViewed'
import ProductListing from 'theme/components/core/ProductListing.vue'

export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  components: {
    ProductListing,
    Product,
    CloseSvg
  },
  computed: {
    sliceRecentlyView () {
      if (this.items && this.items.length > 0) {
        let temp = Array.from(new Set(this.items.slice(0, 8)))
        return temp
      } else {
        return []
      }
    }
  },
  methods: {
    clearWishlist () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Are you sure you would like to remove all the items from the wishlist?'),
        action1: { label: this.$t('OK'),
          action: () => {
            this.$store.dispatch('wishlist/clear')
          }
        },
        action2: { label: this.$t('Cancel'), action: 'close' },
        hasNoTimeout: true
      })
    }
  },
  mixins: [Wishlist, RecentlyViewed]
}
</script>

<style lang="scss" scoped>
.save-margin {
  @media (max-width: 767px) {
    margin-bottom: 55px;
    padding-bottom: env(safe-area-inset-bottom);
  }
}
.title-wishlist {
  font-size: 36px;
   @media (max-width: 767px) {
     font-size: 20px;
   }
}
.link-box {
  display: inline;
}
.close-wishlist {
  right: 40px;
  @media (max-width: 767px) {
    right: 20px;
  }
}
</style>
