<template>
  <li class="row pt20 m0 brdr-bottom-1 brdr-cl-gainsboro wishlist-item">
    <div class="blend col-xs-4 ml20 mobile-margin" @click="closeWishlist">
      <router-link :to="productLink">
        <product-image :image="image" />
      </router-link>
    </div>
    <div class="col-xs between-xs flex pl40 py15 cl-black weight-500">
      <div @click="closeWishlist">
        <router-link :to="productLink" class="h4">
          {{ product.name | htmlDecode }}
        </router-link>
        <div class="mt10">
          <span class="price-special h3" v-if="product.special_price">{{ product.price_incl_tax | price }}</span>
          <span class="price-original h6" v-if="product.special_price">{{ product.original_price_incl_tax | price }}</span>
          <span class="price-wishlist" v-if="!product.special_price">
            Cena od: {{ product.price_incl_tax | price }}
          </span>
        </div>
        <div v-if="showAddToCart" class="">
          <add-to-cart
            v-if="product.type_id === 'simple'"
            :product="product"
            class="wishlist-add-to-cart col-xs-12 col-sm-4 col-md-6"
          />
          <router-link
            v-else
            :to="productLink"
            class="wishlist-add-to-cart no-outline button-full block brdr-none w-100 px10 py20 bg-cl-primary-orange ripple weight-400 h4 cl-white sans-serif fs-medium col-xs-12 col-sm-4 col-md-6"
          >
            {{ $t('Configure') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-xs-1 flex align-right between-xs pb25">
      <div>
        <div @click="removeProductFromWhishList(product)" class="mt5 flex clear-product-wishlist">
          <close-svg class="pointer" width="18px" height="100%" color="#191919" />
        </div>
      </div>
      <!-- <div class="hidden-xs">
        <span class="price-special h3" v-if="product.special_price">{{ product.price_incl_tax | price }}</span>&nbsp;
        <span class="price-original h6" v-if="product.special_price">{{ product.original_price_incl_tax | price }}</span>

        <span class="h3" v-if="!product.special_price">
          {{ product.price_incl_tax | price }}
        </span>
      </div> -->
    </div>
  </li>
</template>

<script>
import config from 'config'
import Product from '@vue-storefront/core/compatibility/components/blocks/Wishlist/Product'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import ProductImage from 'theme/components/core/ProductImage'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/lib/store/filters'
import AddToCart from 'theme/components/core/AddToCart'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'

export default {
  components: {
    ProductImage,
    AddToCart,
    CloseSvg
  },
  mixins: [Product],
  props: {
    showAddToCart: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    }
  },
  methods: {
    removeProductFromWhishList (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been removed from wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.removeFromWishlist(product)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
.blend {
  flex: 0 0 141px;
  // opacity: .8;
  // will-change: opacity;
  // transition: .3s opacity $motion-main;
  // &:hover{
  //    opacity: 1;
  //  }
}
.clear-product-wishlist {
  @media (max-width: 767px) {
    padding-top: 15px !important;
  }
}
.mobile-margin {
  @media (max-width: 767px) {
    margin: 3px !important;
  }
}
.price-wishlist {
  font-size: 24px;
  @media (max-width: 767px) {
    padding-top: 5px;
    font-size: 18px;
  }
}
.col-xs {
  flex-direction: column;
}
input {
  width: 30px;
}
.price-original {
  text-decoration: line-through;
  color: #828282;
  font-size: .95rem;
}
.wishlist-add-to-cart {
  padding: 10px;
  margin: 15px 0;
  min-width: 100px;
  font-size: 14px;
  text-align: center;
  border-radius: 50px;
  @media (max-width: 767px) {
    margin: 20px 0 0 0;
    padding: 7px;
    width: 80%;
  }
}
.price-original {
  text-decoration: line-through;
  color: #828282;
  // font-size: .95rem;
}
.price-special {
  color: #828282;
  @media (max-width: 767px) {
    font-size: 18px;
  }
  // font-size: .95rem;
}
.wishlist-item {
  @media (max-width: 767px) {
    padding-top: 0;
    padding-right: 0;
    div {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
