<template>
  <div class="add-to-cart">
    <button-full @click.native="!isProductDisabled ? addToCart(product) : emitClick()" :class="[{ 'disabled' : isProductDisabled }, {'bg-cl-gray': isAddingToCart}]" data-testid="addToCart" class="w-100 flex padding0">
      <i class="icon-buy icon-add" />
      <span class="text-add">{{ $t('Do koszyka') }}</span>
      <div v-show="isAddingToCart" class="custom-loader">
        <div /><div /><div /><div />
      </div>
    </button-full>
  </div>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import { notifications } from '@vue-storefront/core/modules/cart/helpers'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'

export default {
  directives: { focusClean },
  components: { ButtonFull },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClick () {
      this.$emit('click-add-to-cart')
    },
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    async addToCart (product) {
      try {
        const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product })
        diffLog.clientNotifications.forEach(notificationData => {
          this.notifyUser(notificationData)
        })
      } catch (message) {
        this.notifyUser(notifications.createNotification({ type: 'error', message }))
      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
      this.$store.commit('ui/setAddToCart', false)
    }
  },
  computed: {
    ...mapGetters({
      isAddingToCart: 'cart/getIsAdding'
    }),
    isProductDisabled () {
      return this.disabled || formatProductMessages(this.product.errors) !== '' || this.isAddingToCart
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>

<style lang="scss" scoped>
.add-to-cart {
  &.disabled {
    background-color: #d9d9d9;
  }
}
.padding0 {
  padding: 0 !important;
}
.icon-add {
 position: absolute;
 left: 15px;
 padding: 10px 0;
}
.text-add {
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
}
.custom-loader {
  display: inline-block;
  position: absolute;
  width: 52px;
  height: 52px;
  top: 0;
  left: 45%;
  @media (max-width: 767px) {
    width: 45px;
    height: 45px;
  }
}
.custom-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 38px;
  height: 38px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: custom-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  @media (max-width: 767px) {
    width: 31px;
    height: 31px;
  }
}
.custom-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.custom-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.custom-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes custom-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bg-cl-gray {
  color: #d9d9d9;
}
</style>
